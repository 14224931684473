<template>
  <div class="cashbackPage">
    <div class="page-header">
      <div class="page-header__wrapper">
        <div class="page-header__left">
          <h3 class="title">Cashback Management</h3>
        </div>

        <div class="page-header__right">
          <div style="display: flex">
            <el-dropdown>
              <el-button type="primary" class="mr-1">
                Cashback Bulk Handling<i
                  class="el-icon-arrow-down el-icon--right"
                ></i>
              </el-button>
              <el-dropdown-menu slot="dropdown" class="customDropDown">
                <el-dropdown-item command="true">
                  <el-button
                    style="width: 100%"
                    type="primary"
                    icon="el-icon-top"
                    size="mini"
                    @click="
                      sheetActionClicked(
                        'Assignation Segmentation for cashback management based on user list',
                        'assign'
                      )
                    "
                  >
                    Assign Users To Cashback
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item command="true">
                  <el-button
                    style="width: 100%"
                    type="primary"
                    icon="el-icon-top"
                    size="mini"
                    @click="
                      sheetActionClicked(
                        'Unassign users to Cashback Management',
                        'unassign'
                      )
                    "
                  >
                    UnAssign Users To Cashback
                  </el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <cashbackExportButtons :filter="filters"></cashbackExportButtons>
          </div>
          <el-button
            style="margin-left: 10px"
            icon="el-icon-plus"
            type="primary"
            @click="navigateToSingleCashback('create')"
            size="medium"
            >Create Cashback</el-button
          >
        </div>
      </div>
    </div>
    <!-- Filters -->
    <MetricFilterBox
      :defaultFilterData="cashbackMetrics"
      v-model="metricsList"
      @openMetric="saveMetrics"
      :clearBtnShowen="metricsList.length > 0"
      @clearFilters="clearFilters"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            :is="metric.compName"
            v-model="filters[metric.key]"
            @change="filtersChanged(metric.key, $event)"
            :meta="metric.meta ? metric.meta : {}"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>
    <!-- Filters -->
    <!-- Tabs -->
    <el-tabs v-model="cashbackStatus" @tab-click="cashbackStatusChanged">
      <el-tab-pane label="Upcoming" name="upcoming"> </el-tab-pane>
      <el-tab-pane label="Active" name="active"></el-tab-pane>
      <el-tab-pane label="Deactivated" name="deactivated"></el-tab-pane>
    </el-tabs>
    <!-- Tabs -->
    <!-- CashbackTable -->

    <styled-table
      :data="list"
      class="cashbackTable"
      style="width: 100%"
      @row-click="viewCashbackDetails"
    >
      <el-table-column
        v-for="col in upcomingCashbackTableCols"
        :key="col.prop"
        :prop="col.prop"
        :label="$t(col.label)"
      >
      </el-table-column>
      <el-table-column
        prop="minTransactionAmount"
        label="Transaction From"
        width="200"
      >
        <template slot-scope="prop">
          <div>{{ prop.row.minTransactionAmount }} EGP</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="maxTransactionAmount"
        label="Transaction To"
        width="200"
      >
        <template slot-scope="prop">
          <div>{{ prop.row.maxTransactionAmount }} EGP</div>
        </template>
      </el-table-column>
      <el-table-column prop="cashbackAmount" label="Value" width="150">
        <template slot-scope="prop">
          <div>{{ prop.row.cashbackAmount }} EGP</div>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Calculation Type" width="200">
        <template slot-scope="prop">
          {{ prop.row.cashbackType }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Type" width="150">
        <template slot-scope="prop">
          {{ prop.row.type }}
        </template>
      </el-table-column>
      <el-table-column prop="industryName" label="Industry" width="150">
        <template slot-scope="prop">
          {{ prop.row.industryName && prop.row.industryName }}
          <i v-if="!prop.row.industryName" class="el-icon-minus"></i>
        </template>
      </el-table-column>
      <el-table-column label="Payment Method" width="150">
        <template slot-scope="prop">
          <!-- v-if="prop.row.paymentMethod === 'credit'"  -->
          <el-tag
            :type="
              prop.row.paymentMethod === 'credit'
                ? 'success'
                : prop.row.paymentMethod === 'cash'
                ? 'warning'
                : 'info'
            "
            >{{ prop.row.paymentMethod }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="start_date" label="Start Date" width="140">
        <template slot-scope="prop">
          <div>{{ prop.row.cashbackStartDate | moment("DD/MM/YYYY") }}</div>
          <div class="small-text">
            {{ prop.row.cashbackStartDate | moment("h:mm a") }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="end_date" label="End Date" width="160">
        <template slot-scope="prop">
          <div>{{ prop.row.cashbackEndDate | moment("DD/MM/YYYY") }}</div>
          <div class="small-text">
            {{ prop.row.cashbackEndDate | moment("h:mm a") }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="expirationPeriod"
        label="Expiration Period"
        width="150"
      >
        <template slot-scope="prop" v-if="prop?.row?.expirationPeriod">
          {{ prop?.row?.expirationPeriod }}
          {{ prop?.row?.expirationPeriod == 1 ? "Day" : "Days" }}
        </template>
        <i v-else class="el-icon-minus"></i>
      </el-table-column>
      <el-table-column
        width="260"
        label="Actions"
        fixed="right"
        v-if="cashbackStatus !== 'deactivated'"
      >
        <template slot-scope="scope">
          <div class="operations_btns">
            <el-button
              @click="
                cashbackOperationClicked({ scope, operation: 'edit' }, $event)
              "
              type="success"
              size="small"
              >Edit</el-button
            >
            <el-button
              v-if="
                cashbackStatus === 'upcoming' || cashbackStatus === 'active'
              "
              type="danger"
              size="small"
              @click="deleteCashback(scope, $event)"
              >{{ deleteButtonText() }}</el-button
            >
          </div>
        </template>
      </el-table-column>
    </styled-table>
    <!-- CashbackTable -->
    <!-- Pagination -->
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="false"
        background
        layout="prev, pager, next"
        :page-size="50"
        :total="pagination.totalResultsCount"
        :current-page="+currentPage"
        @current-change="handlePagination"
      ></el-pagination>
    </div>
    <!-- /Pagination -->
    <!-- Uploader Dialog -->
    <el-dialog
      width="40%"
      :title="$t('uploadEntitesSheettitle')"
      :visible.sync="uploaderDialog"
      @close="onClickCloseDialog"
    >
      <div class="uploaderDialog">
        <div class="uploaderDialogBtn__wrapper">
          <div class="uploadContainer">
            <div class="summary">
              <div class="summary__wrapper">
                <el-alert
                  style="margin-bottom: 12px"
                  :closable="false"
                  :title="summary"
                  type="warning"
                  show-icon
                >
                </el-alert>
              </div>
            </div>
            <el-upload
              ref="excel-input"
              :on-change="uploadExcelFileChange"
              :on-remove="removeExcelFile"
              :file-list="excelFileList"
              action="#"
              :multiple="false"
              :auto-upload="false"
              accept=".xlsx"
            >
              <el-button slot="trigger" size="mini" type="primary"
                >Select File</el-button
              >
            </el-upload>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :disabled="excelFileList.length == 0"
          icon="el-icon-top"
          type="primary"
          @click="uploadExcelSheet"
        >
          {{ $t("bundleImport") }}
        </el-button>
        <el-button @click="onClickCloseDialog">{{
          $t("bundleDialogCloseLabel")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- Uploader Dialog -->
  </div>
</template>

<script>
import _ from "lodash";
import cashbackExportButtons from "../cashbackExportButtons.vue";
import styledTable from "../../../components/dataContainers/styledTable.vue";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import metricbox from "@/components/transaction/metricbox";
import { cashbackComponentsMetrics } from "@/components/transaction/metricboxComponents/index";
import { cashbackService } from "@/services/cashback.service.js";
import { upcomingCashbackTableCols, cashbackMetrics } from "../data";
import { utils } from "@/utils";
export default {
  name: "CashbackPage",
  components: {
    styledTable,
    MetricFilterBox,
    metricbox,
    cashbackExportButtons,
    ...cashbackComponentsMetrics,
  },

  data() {
    return {
      cashbackStatus: "upcoming",
      upcomingCashbackTableCols: upcomingCashbackTableCols,
      list: [],
      showCreateDialogFlag: false,
      updateErrorResponseObject: null,
      uploaderDialog: false,
      summary: "",
      excelFileList: [],
      sheetHolder: null,
      sheetAction: null,
      pagination: {},
      metricsList: [],
      filters: {},
      cashbackMetrics: cashbackMetrics,
    };
  },

  computed: {
    currentPage() {
      return this.$route.query.page
        ? Number.parseInt(this.$route.query.page)
        : 1;
    },
  },

  methods: {
    deleteButtonText() {
      return this.cashbackStatus === "upcoming" ? "Delete" : "Deactivate";
    },

    async deleteCashback(scope, event) {
      event.stopPropagation();
      const status =
        this.cashbackStatus === "upcoming" ? "Deleted" : "Deactivated";
      const { type, id } = scope.row;
      try {
        await this.$confirm(
          `This Cashback with id ${id} will permanently ${status}. Continue?`,
          "Warning.",
          {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        await this.deleteReq({ type, id, status });
        this.$globalFunctions.popupMessageWrapper(
          `Selected Cashback ${status} Successfully`,
          "success",
          4000
        );
        this.getCashbackList(
          this.currentPage,
          this.filters,
          this.cashbackStatus
        );
      } catch (error) {
        if (error && error !== "cancel") {
          const errorMessage =
            this.$globalFunctions.errorMessageExtractor(error);
          this.$globalFunctions.popupMessageWrapper(
            errorMessage,
            "error",
            3500
          );
        } else {
          this.$message({
            type: "info",
            message: "Operation has been canceled",
          });
        }
      }
    },

    async deleteReq(params) {
      try {
        const res = await cashbackService.deleteCashback(params);
        if (res.status) {
          return true;
        }
      } catch (error) {
        throw error;
      }
    },

    async cashbackStatusChanged() {
      if (this.$route.fullPath != "/cashback/management?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
      }
      await this.getCashbackList(
        this.currentPage,
        this.filters,
        this.cashbackStatus
      );
    },

    navigateToSingleCashback(action, id, type) {
      if (action === "create") {
        this.$router.push({
          name: "SingleCashback",
          params: { action },
        });
      } else {
        this.$router.push({
          name: "SingleCashback",
          params: { action, id, type },
        });
      }
    },

    viewCashbackDetails(column) {
      if (this.cashbackStatus === "deactivated") {
        return;
      }
      const { id, type } = column;
      this.navigateToSingleCashback("view", id, type);
    },

    cashbackOperationClicked({ scope, operation }, event) {
      const { id, type } = scope.row;
      if (operation === "edit") {
        this.navigateToSingleCashback("edit", id, type);
        event.stopPropagation();
      }
    },

    clearFilters() {
      this.filters = {};
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.cashbackMetrics = this.filters;
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem("cashbackMetrics", []);
      this.reloadPage();
    },

    async reloadPage() {
      if (this.$route.fullPath != "/cashback/management?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      await this.getCashbackList(
        this.currentPage,
        this.filters,
        this.cashbackStatus
      );
    },

    saveMetrics() {
      localStorage.setItem("cashbackMetrics", JSON.stringify(this.metricsList));
    },

    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      //add check if filter exist to avoid unnecessary api calls
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem("cashbackMetrics", JSON.stringify(this.metricsList));
      //fix lodash with reactivity issue
      this.metricsList = [...this.metricsList];
    },

    async filtersChanged(key, value) {
      if (_.isEmpty(value)) {
        this.filters[key] = undefined;
      }
      // save filters in session
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.cashbackMetrics = { ...this.filters };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.filters = { ...this.filters };

      if (this.$route.fullPath != "/cashback/management?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
      }
      await this.getCashbackList(
        this.currentPage,
        this.filters,
        this.cashbackStatus
      );
    },

    async getCashbackList(page = 1, filters, cashbackStatus) {
      const { type, cashbackId } = filters;
      try {
        if (filters && cashbackId && !type) {
          const errorMessage =
            "You Should Apply Type Filter While Using Cashback ID Filter";
          utils.popupMessageWrapper(errorMessage, "error", 3500);
          delete this.filters.cashbackId;
        }
        const response = await cashbackService.fetchCashback(
          page,
          filters,
          cashbackStatus
        );
        // eslint-disable-next-line no-unused-vars
        let responseData = response.data.data.result;
        this.pagination = {
          totalPages: response.data.data.pages,
          totalResultsCount: response.data.data.count,
          nextPage: response.data.data.next,
        };
        this.list = responseData;
        return response;
      } catch (error) {
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },

    uploadExcelFileChange(file) {
      // Allow only one file in the file list not multiple
      this.excelFileList = [file];
      const rawFile = this.excelFileList[0];
      if (!rawFile) return;
      if (/\.(csv|xlsx)$/.test(rawFile.name)) {
        this.sheetHolder = rawFile;
      } else {
        this.$message({
          message: this.$t("promocodesPageFileValidationErrorMessage"),
          type: "error",
          duration: 4000,
        });
        this.excelFileList = [];
      }
    },

    removeExcelFile() {
      this.excelFileList = [];
    },

    uploadExcelSheet() {
      this.sheetActionHandler();
    },

    sheetActionHandler() {
      let form = new FormData();
      form.append("file", this.excelFileList[0].raw);
      let cashbackType = this.filters?.type ?? null;

      cashbackService
        .uploadCashbackSegmentationSheetAction(
          cashbackType,
          this.sheetAction,
          form
        )
        .then((res) => {
          const errorFileHeadersName =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          if (
            res.status == 200 &&
            res.headers["content-type"] != errorFileHeadersName
          ) {
            this.$message({
              message: `${res.data.message ?? "file uploaded successfully"}`,
              type: "success",
              duration: 6000,
            });
          }
          if (
            res.status == 200 &&
            (this.sheetAction == "assign" || this.sheetAction == "unassign") &&
            res.headers["content-type"] == errorFileHeadersName
          ) {
            const responseData = res.data;
            let blob = new Blob([responseData], {
              type: res.headers["content-type"],
            });
            utils.downloadBlob(blob, "BulkCashbackErrors", "xlsx");
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
          if (res?.response?.status === 400) {
            this.$globalFunctions.popupMessageWrapper(
              `There is an error occured`,
              "error",
              2000
            );
          }
        })
        .catch((err) => {
          const errorMessage = this.$globalFunctions.errorMessageExtractor(err);
          this.$globalFunctions.popupMessageWrapper(
            errorMessage,
            "error",
            2000
          );
        })
        .finally(() => {
          this.sheetAction = null;
          this.sheetHolder = null;
          this.summary = "";
          this.excelFileList = [];
          this.uploaderDialog = false;
        });
    },

    sheetActionClicked(summary, chosenAction) {
      const { type } = this.filters;
      if (this.filters && type) {
        this.summary = summary;
        this.uploaderDialog = true;
        this.sheetAction = chosenAction;
        return;
      } else {
        const errorMessage =
          "You Should Apply Type Filter While Using Cashback Sheet Handler";
        utils.popupMessageWrapper(errorMessage, "error", 3500);
        return;
      }
    },

    onClickCloseDialog() {
      this.uploaderDialog = false;
      this.sheetAction = null;
    },

    handlePagination(newPage) {
      this.getCashbackList(newPage, this.filters, this.cashbackStatus);
      this.$router.push({
        query: {
          page: newPage,
        },
      });
    },
  },

  async mounted() {
    try {
      const sessionFilters = JSON.parse(sessionStorage.getItem("filter"));
      this.filters =
        sessionFilters && sessionFilters.cashbackMetrics
          ? sessionFilters.cashbackMetrics
          : {};
    } catch (error) {
      console.error("Error parsing session storage filter:", error);
      this.filters = {};
    }

    try {
      const metricsSession = JSON.parse(
        localStorage.getItem("cashbackMetrics")
      );
      if (metricsSession) {
        this.metricsList = metricsSession;
      }
    } catch (error) {
      console.error("Error parsing local storage metrics:", error);
      this.metricsList = [];
    }

    await this.getCashbackList(
      this.currentPage,
      this.filters,
      this.cashbackStatus
    );
  },
};
</script>
<style lang="scss">
.cashbackPage {
  .page-header {
    &__wrapper {
      padding: 10px !important;
    }

    .title {
      display: flex;
      justify-content: center;
      align-content: center;
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fdcd12;
        font-size: 22px;
        margin-right: 4px;
      }
    }
    &__right {
      display: flex;
    }
  }
  .cashbackTable.el-table .cell {
    direction: ltr;
  }
  .cashbackTable .table-header:nth-child(1) {
    border-top-left-radius: 8px;
  }
  .cashbackTable .table-header:last-child {
    border-top-right-radius: 8px;
  }
  .cashbackTable .cell {
    direction: rtl;
  }
  .cashbackTable .table-header {
    background: #fccd13;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
